import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3298eff2 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _e6de4284 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _58aa31f3 = () => interopDefault(import('../pages/evenementen/index.vue' /* webpackChunkName: "pages/evenementen/index" */))
const _b4e33a06 = () => interopDefault(import('../pages/gegevens-aanpassen.vue' /* webpackChunkName: "pages/gegevens-aanpassen" */))
const _04d8e47a = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _4c214634 = () => interopDefault(import('../pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _c4d61480 = () => interopDefault(import('../pages/magazines/index.vue' /* webpackChunkName: "pages/magazines/index" */))
const _44bdb3f4 = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _edade016 = () => interopDefault(import('../pages/profiel.vue' /* webpackChunkName: "pages/profiel" */))
const _210a12d7 = () => interopDefault(import('../pages/recepten/index.vue' /* webpackChunkName: "pages/recepten/index" */))
const _ec456b20 = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _0d24e19a = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _7faa0f67 = () => interopDefault(import('../pages/verifieer.vue' /* webpackChunkName: "pages/verifieer" */))
const _09fa2b11 = () => interopDefault(import('../pages/wachtwoord-aanpassen.vue' /* webpackChunkName: "pages/wachtwoord-aanpassen" */))
const _02cce903 = () => interopDefault(import('../pages/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/wachtwoord-vergeten" */))
const _392b5d2b = () => interopDefault(import('../pages/welbewust-assortiment/index.vue' /* webpackChunkName: "pages/welbewust-assortiment/index" */))
const _2e626466 = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _f6c5205a = () => interopDefault(import('../pages/menu/samenstellen.vue' /* webpackChunkName: "pages/menu/samenstellen" */))
const _dacb078c = () => interopDefault(import('../pages/services/aanvragen.vue' /* webpackChunkName: "pages/services/aanvragen" */))
const _538fdb09 = () => interopDefault(import('../pages/templates/default.vue' /* webpackChunkName: "pages/templates/default" */))
const _8cdcf820 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _30e24eaa = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _56f390ab = () => interopDefault(import('../pages/evenementen/_slug.vue' /* webpackChunkName: "pages/evenementen/_slug" */))
const _c8435710 = () => interopDefault(import('../pages/magazines/_slug.vue' /* webpackChunkName: "pages/magazines/_slug" */))
const _1f53718f = () => interopDefault(import('../pages/recepten/_slug.vue' /* webpackChunkName: "pages/recepten/_slug" */))
const _1092242a = () => interopDefault(import('../pages/services/_slug.vue' /* webpackChunkName: "pages/services/_slug" */))
const _0d862bb0 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _3774bbe3 = () => interopDefault(import('../pages/welbewust-assortiment/_slug.vue' /* webpackChunkName: "pages/welbewust-assortiment/_slug" */))
const _41f4a248 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _3298eff2,
    name: "blogs"
  }, {
    path: "/contact",
    component: _e6de4284,
    name: "contact"
  }, {
    path: "/evenementen",
    component: _58aa31f3,
    name: "evenementen"
  }, {
    path: "/gegevens-aanpassen",
    component: _b4e33a06,
    name: "gegevens-aanpassen"
  }, {
    path: "/inloggen",
    component: _04d8e47a,
    name: "inloggen"
  }, {
    path: "/magazine",
    component: _4c214634,
    name: "magazine"
  }, {
    path: "/magazines",
    component: _c4d61480,
    name: "magazines"
  }, {
    path: "/menu",
    component: _44bdb3f4,
    name: "menu"
  }, {
    path: "/profiel",
    component: _edade016,
    name: "profiel"
  }, {
    path: "/recepten",
    component: _210a12d7,
    name: "recepten"
  }, {
    path: "/registreren",
    component: _ec456b20,
    name: "registreren"
  }, {
    path: "/services",
    component: _0d24e19a,
    name: "services"
  }, {
    path: "/verifieer",
    component: _7faa0f67,
    name: "verifieer"
  }, {
    path: "/wachtwoord-aanpassen",
    component: _09fa2b11,
    name: "wachtwoord-aanpassen"
  }, {
    path: "/wachtwoord-vergeten",
    component: _02cce903,
    name: "wachtwoord-vergeten"
  }, {
    path: "/welbewust-assortiment",
    component: _392b5d2b,
    name: "welbewust-assortiment"
  }, {
    path: "/zoekresultaten",
    component: _2e626466,
    name: "zoekresultaten"
  }, {
    path: "/menu/samenstellen",
    component: _f6c5205a,
    name: "menu-samenstellen"
  }, {
    path: "/services/aanvragen",
    component: _dacb078c,
    name: "services-aanvragen"
  }, {
    path: "/templates/default",
    component: _538fdb09,
    name: "templates-default"
  }, {
    path: "/",
    component: _8cdcf820,
    name: "index"
  }, {
    path: "/blogs/:slug",
    component: _30e24eaa,
    name: "blogs-slug"
  }, {
    path: "/evenementen/:slug",
    component: _56f390ab,
    name: "evenementen-slug"
  }, {
    path: "/magazines/:slug",
    component: _c8435710,
    name: "magazines-slug"
  }, {
    path: "/recepten/:slug",
    component: _1f53718f,
    name: "recepten-slug"
  }, {
    path: "/services/:slug",
    component: _1092242a,
    name: "services-slug"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _0d862bb0,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/welbewust-assortiment/:slug?",
    component: _3774bbe3,
    name: "welbewust-assortiment-slug"
  }, {
    path: "/*",
    component: _41f4a248,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
